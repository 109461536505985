<template>
  <div class="app-content content ">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title float-start mb-0">
                {{ labels[0]['menu_format'] ? labels[0]['menu_format'] + ' - ' : '' }}{{ labels[0]['section_format'] }}
              </h2>
            </div>
          </div>
        </div>
        <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
          <div class="mb-1 breadcrumb-right">
            <a
              class="btn btn-icon btn-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Back to section labels"
              @click="
                $router.push({
                  name: 'admin.section-labels.index',
                })
              "
            ><i
              data-feather="chevron-left"
            /></a>
          </div>
        </div>
      </div>
      <div class="content-body content-body--senior-call">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-header">
                <h3 class="cart-title">
                  <a data-action="collapse">{{ labels.length }} items</a>
                </h3>
                <div class="heading-elements">
                  <ul class="list-inline mb-0">
                    <li>
                      <a data-action="collapse"><i data-feather="chevron-down" /></a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="card-content collapse show">
                <div class="card-body">
                  <div class="row">
                    <div class="col">
                      <div
                        v-for="label in labels"
                        :key="label.id"
                        class="mb-1"
                      >
                        <label
                          for=""
                          class="form-label dis-b"
                        >{{ label.name_format }}<span v-if="label.subSection"> - {{ label.sub_section_format }}</span></label>
                        <input
                          v-model="label.value"
                          type="text"
                          class="form-control"
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

        <div class="page-actions">
          <button
            v-if="!sending"
            id="type-success"
            class="btn btn-success"
            @click="save"
          >
            <i data-feather="save" />
            <span
              class="ms-25 align-middle"
            >Save and Exit</span>
          </button>
          <button
            v-else
            class="btn btn-outline-success"
            type="button"
            disabled=""
          >
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
            <span
              class="ms-25 align-middle"
            >Saving...</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      itemId: this.$route.params.section,
      loading: true,
      sending: false,
      errors: '',
    }
  },
  computed: {
    ...mapGetters({
      labels: 'sectionLabels/item',
    }),
  },
  async mounted() {
    setTimeout(() => {
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
    await this.$store.dispatch('sectionLabels/fetchSection', this.itemId)
    this.loading = false
  },
  methods: {
    async save() {
      this.sending = true
      try {
        await this.$store.dispatch('sectionLabels/update', {
          section: this.itemId,
          data: this.labels,
        })
      } catch (e) {
        this.errors = e.response.data.message
      }

      if (this.errors === '') {
        Vue.swal(
          'The labels has been saved correctly!',
          '',
          'success',
        ).then(() => {
          this.$router.push({ name: 'admin.section-labels.index' })
        })
      } else {
        Vue.swal('Error on save labels!', '', 'error').then(() => {
          this.$router.push({ name: 'admin.section-labels.index' })
        })
      }

      this.sending = false
    },
  },
}
</script>
